<template>
  <v-card
    flat
    height="90vh"
    width-full
    class="d-flex flex-row"
    style="background-color:blue;"
  >
    <channel-list />

    <v-card
      flat
      tile
      style="min-width: 300px;"
      class="pa-2 w-full flex-grow-1"
    >
      <router-view class="row3" />
    </v-card>

  </v-card>
</template>

<script>
import ChannelList from '@/messaging/components/channel/list/index.vue'

export default {
  components: {
    ChannelList
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
